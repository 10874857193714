<template>
  <td colspan="11">
    <div class="custom_title_text_wrap">
      <div class="custom_title">Заголовок: <Nullable :value="item.title" /></div>
    </div>
    <div class="custom_title_text_wrap">
      <div class="custom_title">Описание: <Nullable :value="item.description" /></div>
    </div>
  </td>
</template>

<script>
  import Nullable from '@/components/Common/Nullable'
  export default {
    components: {Nullable},
    props: ["item"]
  }
</script>
