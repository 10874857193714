<template>
  <v-dialog v-model="filter_dialog.status" @click:outside="closeFilterDialog" @keydown.esc="closeFilterDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                    v-model="item.site_id"
                    item-text="url"
                    item-value="id"
                    :items="sites_filter"
                    label="Фильтр по сайту"
                    clearable
                    :error-messages="errors.site_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                    v-model="item.provider_id"
                    item-text="url"
                    item-value="id"
                    :items="providers_filter"
                    label="Фильтр по провайдеру"
                    clearable
                    :error-messages="errors.provider_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-autocomplete
                    v-model="item.category_id"
                    item-text="url"
                    item-value="id"
                    :items="categories_filter"
                    label="Фильтр по категории"
                    clearable
                    :error-messages="errors.category_id"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeFilterDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
      }
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        seo: 'seo/SEO',
        sites: 'sites/SITES',
        providers: 'providers/PROVIDERS',
        categories: 'categories/CATEGORIES',
      }),

      item: function () {
        var item = this.filter_dialog.item;
        if (item) {
          return {
            site_id: item.site_id ? item.site_id : '',
            provider_id: item.provider_id ? item.provider_id : '',
            category_id: item.category_id ? item.category_id : '',
          }
        }
        else {
          return {
            site_id: '',
            provider_id: '',
            category_id: ''
          }
        }
      },
      sites_filter: function () {
        let list = [{
          id: 'null',
          url: 'Не задано'
        }]
        list.push(...(this.sites.data))

        return list
      },
      providers_filter: function () {
        let list = [{
          id: 'null',
          url: 'Не задано'
        }]
        list.push(...(this.providers.data))

        return list
      },
      categories_filter: function () {
        let list = [{
          id: 'null',
          url: 'Не задано'
        }]
        list.push(...(this.categories.data))

        return list
      }
    },
    methods: {
      ...mapActions({
        unsetFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_FALSE',
        closeFilterDialog: 'filter_dialog/CLOSE_FILTER_DIALOG',
        saveFilterDialog: 'filter_dialog/SET_FILTER_DIALOG_ITEM',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSeo: 'seo/GET_SEO',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_ALL_SITES',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        getCategories: 'categories/GET_ALL_CATEGORIES',
        clearSites: 'sites/CLEAR_SITES',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        clearCategories: 'categories/CLEAR_CATEGORIES',
      }),

      submitForm () {
        this.setSnackbar({message: "Фильтр применён"});
        this.errors = false;
        let params = Object.assign({}, this.item);
        if (!params.site_id) delete params.site_id;
        if (!params.provider_id) delete params.provider_id;
        if (!params.category_id) delete params.category_id;
        this.saveFilterDialog(Object.assign({}, params));
        this.closeFilterDialog();
        this.disabledAndLoading();
        params.per_page = this.seo.meta.per_page;
        this.getSeo(params)
            .then(() => {
              this.nonDisabledAndLoading();
            });
      }
    }
}
</script>
